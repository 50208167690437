<template>
  <div v-if="mascotteFile" :key="$route.path">
    <Lottie v-if="lottieMascotte" class="mascotte" :options="defaultOptions" :height="400" :width="400" v-on:animCreated="handleAnimation"/>
    <div v-else class="mascotte">
      <img :src="mascotteFile" :alt="`Mascotte ${$canonicalUser}`">
    </div>
  </div>
</template>

<script>
/**
 * This component is used to display the mascotte of the application, preferred the Lottie, else we fallback to mascotte.png.
 * First we check if there is a Lottie available specific for this activityID, if not we check if there is a Lottie available for the preferredNo (page).
 * For skill specific mascottes we check if there is a Lottie available for the preferredSkill, if not we fallback to the default mascotte (if we have).
 *
 * Examples of usage:
 * Place an image in the build/{site}/img folder with the name mascotte-[preferredActivityID]
 *  - There will be checked for this ActivityId if there is a preferredNo specific mascotte available > mascotte-[preferredActivityID]-[preferredNo].json
 *  - Specific for the skill page we check if there is a mascotte-[preferredActivityID]-[preferredSkill].json
 *  - If we don't find, we check if there is a skill specific mascotte available > mascotte-[preferredSkill].json
 *  - If we don't find, we check if there is any specific for this page (preferredNo) > mascotte-[preferredNo].json
 *  - If we don't find, we fallback to the default mascotte > mascotte.json
 *  - If we don't find, we fallback to the default mascotte > mascotte.png
 *  - If we don't find, we fallback to showing no mascotte at all.
 */
import { has } from 'lodash'
import Lottie from '@/components/Lottie'

export default {
  name: 'TheMascotte',

  props: {
    preferredActivityID: String,
    preferredNo: Number,
    preferredSkill: String
  },

  components: {
    Lottie
  },

  computed: {
    defaultOptions () {
      return {
        loop: true,
        autoplay: true,
        animationData: this.mascotteFile
      }
    },

    lottieMascotte () {
      if (has(this.mascotteFile, 'v')) {
        return true
      }

      return this.mascotteFile && this.mascotteFile.slice(-4) !== '.png'
    },

    mascotteFile () {
      switch (this.preferredNo) {
        case 1:
          try {
            return require(`@/build/${this.$canonicalUser}/img/mascotte-${this.preferredNo}.json`)
          } catch {
            return this.getDefaultMascotte()
          }
        case 2:
          try {
            return require(`@/build/${this.$canonicalUser}/img/mascotte-${this.preferredActivityID}-${this.preferredNo}.json`)
          } catch {
            try {
              return require(`@/build/${this.$canonicalUser}/img/mascotte-${this.preferredNo}.json`)
            } catch {
              return this.getDefaultMascotte()
            }
          }
        case 3:
          try {
            return require(`@/build/${this.$canonicalUser}/img/mascotte-${this.preferredActivityID}-${this.preferredSkill}.json`)
          } catch {
            try {
              return require(`@/build/${this.$canonicalUser}/img/mascotte-${this.preferredSkill}.json`)
            } catch {
              try {
                return require(`@/build/${this.$canonicalUser}/img/mascotte-${this.preferredNo}.json`)
              } catch {
                return this.getDefaultMascotte()
              }
            }
          }
        default:
          return this.getDefaultMascotte()
      }
    }
  },

  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
    },

    getDefaultMascotte () {
      try {
        return require(`@/build/${this.$canonicalUser}/img/mascotte.json`)
      } catch (error) {
        try {
          return require(`@/build/${this.$canonicalUser}/img/mascotte.png`)
        } catch (error) {
          return false
        }
      }
    }
  }
}
</script>
